import React from "react"
import { Link } from "gatsby";
import Layout from "../components/Layout/Layout"
import Helper from "../components/Helper/Helper"
import Footer from "../components/Footer/Footer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGhost } from "@fortawesome/free-solid-svg-icons";

const NotFoundPage = () => {

  const styling = Helper.styling({style: 'special__one', color_1: 'bde4ff', color_2: 'f8f9fa', color_3: 'f8f9fa', color_4: 'f8f9fa', level: 'root'});

  return ( 
    <Layout>
      <div id="content" className="d-flex flex-column justify-content-between" style={{minHeight: '90%'}}>
        <div className="d-flex flex-column">
          <div className="container__banner p-4">
            <div className="jumbotron jumbotron-fluid m-0 p-5" style={styling}>
              <div className="container">
                <FontAwesomeIcon icon={faGhost} className="fa__big mt-5 mb-3 me-3 d-inline-block" aria-label="Warning" rel="noopener" size="4x" />
                <h1 className="display-4 d-inline-block fw-bolder">Not found</h1>
                <p className="lead">You just hit a route that doesn&#39;t exist... the sadness.</p>
                <hr className="my-4" />
                <p></p>
                <p className="lead my-5">
                    <Link to="/" className="btn btn-dark btn-lg" role="button">Back home</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Layout>
  );
}

export default NotFoundPage
